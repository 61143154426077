/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    code: "code",
    ul: "ul",
    li: "li",
    h3: "h3",
    em: "em"
  }, _provideComponents(), props.components), {Boss, Boon, Skill, Trait, Specialization, Effect, Traits, SpecialActionKey} = _components;
  if (!Boon) _missingMdxReference("Boon", true);
  if (!Boss) _missingMdxReference("Boss", true);
  if (!Effect) _missingMdxReference("Effect", true);
  if (!Skill) _missingMdxReference("Skill", true);
  if (!SpecialActionKey) _missingMdxReference("SpecialActionKey", true);
  if (!Specialization) _missingMdxReference("Specialization", true);
  if (!Trait) _missingMdxReference("Trait", true);
  if (!Traits) _missingMdxReference("Traits", true);
  return React.createElement(React.Fragment, null, React.createElement(Boss, {
    name: "mama",
    video: "p2wUMQEQNUY",
    timestamp: "",
    videoCreator: "Deen [dT]",
    foodId: "43360",
    utilityId: "50082",
    legend1Id: "41858",
    legend2Id: "28134",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "sword",
    weapon1MainSigil1: "impact",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "Berserker",
    weapon1OffType: "sword",
    weapon1OffSigil: "force",
    weapon1OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "staff",
    weapon2MainSigil1: "severance",
    weapon2MainSigil2: "force",
    weapon2MainInfusion1Id: "37131",
    weapon2MainInfusion2Id: "37131",
    video: "p2wUMQEQNUY",
    videoCreator: "Deen [dT]",
    foodId: "43360",
    utilityId: "50082",
    legend1Id: "41858",
    legend2Id: "28134",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon2MainInfusion2Id: "37131",
    weapon1MainId: "46774",
    weapon1MainSigil1Id: "24868",
    weapon1OffId: "46774",
    weapon1OffSigilId: "24615",
    weapon2MainId: "46773",
    weapon2MainSigil1Id: "84505",
    weapon2MainSigil2Id: "24615"
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Suggested Boon Duration:"), " ", React.createElement(_components.code, null, "50%")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Maintaining ", React.createElement(Boon, {
    name: "Alacrity"
  }), " in this encounter does not require high boon duration due to the length of the fight. This allows you to take a more damage focused approach. Increase your concentration if you notice downtime in ", React.createElement(Boon, {
    name: "Alacrity"
  }), "."), "\n")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Ordersfromabove",
    id: "45537"
  }), " for ", React.createElement(Boon, {
    name: "Alacrity"
  }), " and ", React.createElement(Skill, {
    name: "Renewingwave",
    id: "29321"
  }), " to blast ", React.createElement(Boon, {
    name: "Might"
  }), ", take the mistlock and repeat. The two casts of ", React.createElement(Skill, {
    name: "Ordersfromabove",
    id: "45537"
  }), " overlap and grant twice the duration of ", React.createElement(Boon, {
    name: "Alacrity"
  }), ". You can use ", React.createElement(Skill, {
    name: "DroptheHammer",
    id: "28110"
  }), " for an additional ", React.createElement(Boon, {
    name: "Might"
  }), " blast if needed."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "After the introduction of templates, it is recommended to precast using equipment with higher boon duration."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "100 - 75%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "DarkrazorsDaring",
    id: "41220"
  }), ", and ", React.createElement(Skill, {
    name: "IcerazorsIre",
    id: "40485"
  }), " on MAMA."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Surgeofthemists",
    id: "28978"
  }), " to CC. Be aware of your positioning to gain an additional hit."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Weapon swap and change to ", React.createElement(Skill, {
    name: "legendaryassassinstance",
    id: "28134"
  }), " to gain the extra energy from the trait ", React.createElement(Trait, {
    name: "ChargedMists",
    id: "1791"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Activate ", React.createElement(Skill, {
    name: "Impossibleodds",
    id: "27107"
  }), ", and use ", React.createElement(Skill, {
    name: "citadel bombardment",
    id: "41294"
  }), " and ", React.createElement(Skill, {
    name: "ChillingIsolation",
    id: "29233"
  }), " before sharing ", React.createElement(Boon, {
    name: "Alacrity"
  }), " with ", React.createElement(Skill, {
    name: "OrdersfromAbove",
    id: "45537"
  }), "."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "1st Add")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Shackling Wave",
    id: "28472"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Change to ", React.createElement(Skill, {
    name: "Legendary Renegade stance",
    id: "41858"
  }), " to use ", React.createElement(Skill, {
    name: "Heroic Command",
    id: "44076"
  }), " to share ", React.createElement(Boon, {
    name: "Might"
  }), "."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "75-50%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "SoulcleavesSummit",
    id: "45773"
  }), " and ", React.createElement(Skill, {
    name: "icerazors ire",
    id: "40485"
  }), " on MAMA."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " with ", React.createElement(Skill, {
    name: "Orders from Above",
    id: "45537"
  }), " when off cooldown."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Weapon swap to staff near the end of the phase."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "2nd Add")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "Darkrazors Daring",
    id: "41220"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Change to ", React.createElement(Skill, {
    name: "Legendary Assassin stance",
    id: "28134"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Might"
  }), " with ", React.createElement(Skill, {
    name: "Heroic Command",
    id: "44076"
  }), "."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "50-25%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Surge of the Mists",
    id: "28978"
  }), ", and weapon swap to swords."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Activate ", React.createElement(Skill, {
    name: "Impossible Odds",
    id: "27107"
  }), ", cast ", React.createElement(Skill, {
    name: "Citadel Bombardment",
    id: "41294"
  }), " and ", React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Change to ", React.createElement(Skill, {
    name: "Legendary Renegade stance",
    id: "41858"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " with ", React.createElement(Skill, {
    name: "Orders from Above",
    id: "45537"
  }), " when off cooldown."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "3rd Add")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "Darkrazors Daring",
    id: "41220"
  }), ", ", React.createElement(Skill, {
    name: "icerazors ire",
    id: "40485"
  }), ", and ", React.createElement(Skill, {
    name: "Soulcleaves Summit",
    id: "45773"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Might"
  }), " with ", React.createElement(Skill, {
    name: "Heroic Command",
    id: "44076"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Weapon swap to staff when possible."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "25-0%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Warding Rift",
    id: "29288"
  }), " for MAMAs shockwaves."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Using ", React.createElement(Skill, {
    name: "Surge of the Mists",
    id: "28978"
  }), " to CC."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Weapon swap and change to ", React.createElement(Skill, {
    name: "Legendary Assassin Stance",
    id: "28134"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Activate ", React.createElement(Skill, {
    name: "Impossible Odds",
    id: "27107"
  }), ", cast ", React.createElement(Skill, {
    name: "Citadel Bombardment",
    id: "41294"
  }), " and ", React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), "."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Annotation")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If your second phase is fast you might want to deactivate ", React.createElement(Skill, {
    name: "Soulcleaves Summit",
    id: "45773"
  }), " to have enough energy to share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " with ", React.createElement(Skill, {
    name: "Orders from Above",
    id: "45537"
  }), " and precast ", React.createElement(Skill, {
    name: "Darkrazors Daring",
    id: "41220"
  }), " on the second add."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If your second phase is slow you can let ", React.createElement(Skill, {
    name: "Soulcleaves Summit",
    id: "45773"
  }), " run out and then let your energy replenish to share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " and precast ", React.createElement(Skill, {
    name: "Darkrazors Daring",
    id: "41220"
  }), " on the second add."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Adjust your weapon swap to staff in the second phase to accommodate the weapon swap back to swords for the burst in phase three."), "\n"), "\n"), "\n", React.createElement(Boss, {
    name: "siax",
    video: "FwVEqdngirM",
    timestamp: "",
    videoCreator: "Deen [dT]",
    foodId: "43360",
    utilityId: "50082",
    legend1Id: "41858",
    legend2Id: "28134",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "sword",
    weapon1MainSigil1: "impact",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "Berserker",
    weapon1OffType: "sword",
    weapon1OffSigil: "serpentslaying",
    weapon1OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "staff",
    weapon2MainSigil1: "severance",
    weapon2MainSigil2: "serpentslaying",
    weapon2MainInfusion1Id: "37131",
    weapon2MainInfusion2Id: "37131",
    video: "FwVEqdngirM",
    videoCreator: "Deen [dT]",
    foodId: "43360",
    utilityId: "50082",
    legend1Id: "41858",
    legend2Id: "28134",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon2MainInfusion2Id: "37131",
    weapon1MainId: "46774",
    weapon1MainSigil1Id: "24868",
    weapon1OffId: "46774",
    weapon1OffSigilId: "24658",
    weapon2MainId: "46773",
    weapon2MainSigil1Id: "84505",
    weapon2MainSigil2Id: "24658"
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Suggested Boon Duration:"), " ", React.createElement(_components.code, null, "50%")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Maintaining ", React.createElement(Boon, {
    name: "Alacrity"
  }), " in this encounter does not require high boon duration due to the length of the fight. This allows you to take a more damage focused approach. Increase your concentration if you notice downtime in ", React.createElement(Boon, {
    name: "Alacrity"
  }), "."), "\n")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Ordersfromabove",
    id: "45537"
  }), " for ", React.createElement(Boon, {
    name: "Alacrity"
  }), " and ", React.createElement(Skill, {
    name: "Renewingwave",
    id: "29321"
  }), " to blast ", React.createElement(Boon, {
    name: "Might"
  }), ", take the ", React.createElement(_components.em, null, "Mistlock Singularity"), " and repeat. The two casts of ", React.createElement(Skill, {
    name: "Ordersfromabove",
    id: "45537"
  }), " overlaps and grants twice the duration of ", React.createElement(Boon, {
    name: "Alacrity"
  }), ". You can use ", React.createElement(Skill, {
    name: "DroptheHammer",
    id: "28110"
  }), " for an additional ", React.createElement(Boon, {
    name: "Might"
  }), " blast if needed."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "After the introduction of templates, it is recommended to precast using equipment with higher boon duration."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Ask your ", React.createElement(Specialization, {
    name: "Weaver"
  }), " to precast ", React.createElement(Skill, {
    name: "Conjure Frost Bow",
    id: "5567"
  }), " for your opening rotation."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "100 - 66%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Have your staff equipped and pick up the ", React.createElement(Skill, {
    name: "Conjure Frost Bow",
    id: "5567"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "Darkrazors Daring",
    id: "41220"
  }), " and ", React.createElement(Skill, {
    name: "icerazors ire",
    id: "40485"
  }), " and change to ", React.createElement(Skill, {
    name: "Legendary Assassin Stance",
    id: "28134"
  }), " ", React.createElement(_components.strong, null, "using the legend swap UI"), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Once the encounter begins activate ", React.createElement(Skill, {
    name: "Impossible Odds",
    id: "27107"
  }), " and use ", React.createElement(Skill, {
    name: "Citadel Bombardment",
    id: "41294"
  }), ", ", React.createElement(Skill, {
    name: "Frost Storm",
    id: "5723"
  }), ", and ", React.createElement(Skill, {
    name: "Frost Fan",
    id: "5568"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Change to ", React.createElement(Skill, {
    name: "Legendary Renegade Stance",
    id: "41858"
  }), ", this will grant you extra energy from the trait ", React.createElement(Trait, {
    name: "Charged Mists",
    id: "1791"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Drop the ", React.createElement(Skill, {
    name: "Conjure Frost Bow",
    id: "5567"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Immediately use ", React.createElement(Skill, {
    name: "Surgeofthemists",
    id: "28978"
  }), " to CC."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "When you are through the hitbox weapon swap and use ", React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Might"
  }), " at the end of the phase with ", React.createElement(Skill, {
    name: "Heroic Command",
    id: "44076"
  }), "."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "1st Add")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "icerazors ire",
    id: "40485"
  }), " on your designated add."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Shackling Wave",
    id: "28472"
  }), " and ", React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), " to finish off the add."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Soulcleaves Summit",
    id: "45773"
  }), " on Siax."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Deathstrike",
    id: "27074"
  }), " to shadow step to Siax immediately."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "66-33%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " with ", React.createElement(Skill, {
    name: "Orders from Above",
    id: "45537"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Let ", React.createElement(Skill, {
    name: "Soulcleaves summit",
    id: "45773"
  }), " and ", React.createElement(Skill, {
    name: "Shackling wave",
    id: "28472"
  }), " deplete your energy."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Change to ", React.createElement(Skill, {
    name: "Legendary Assassin Stance",
    id: "28134"
  }), ", this will grant you extra energy from the trait ", React.createElement(Trait, {
    name: "Charged Mists",
    id: "1791"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Citadel Bombardment",
    id: "41294"
  }), " and ", React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Might"
  }), " at the end of the phase with ", React.createElement(Skill, {
    name: "Heroic Command",
    id: "44076"
  }), "."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "2nd Add")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Activate ", React.createElement(Skill, {
    name: "Impossible Odds",
    id: "27107"
  }), " and use ", React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), " to kill your add quickly."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Save ", React.createElement(Skill, {
    name: "Shackling Wave",
    id: "28472"
  }), " for the next phase."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Deathstrike",
    id: "27074"
  }), " to shadow step back to Siax immediately."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "33-0%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " with ", React.createElement(Skill, {
    name: "Orders from Above",
    id: "45537"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Shackling wave",
    id: "28472"
  }), " to deplete your energy."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Change to ", React.createElement(Skill, {
    name: "Legendary Renegade Stance",
    id: "41858"
  }), ", this will grant you extra energy from the trait ", React.createElement(Trait, {
    name: "Charged Mists",
    id: "1791"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Soulcleaves Summit",
    id: "45773"
  }), ", ", React.createElement(Skill, {
    name: "Citadel Bombardment",
    id: "41294"
  }), ", ", React.createElement(Skill, {
    name: "Heroic Command",
    id: "44076"
  }), ", and ", React.createElement(Skill, {
    name: "Icerazors Ire",
    id: "40485"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), " and ", React.createElement(Skill, {
    name: "Shackling Wave",
    id: "28472"
  }), " off cooldown until the encounter is finished."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Annotation")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If you do not have a ", React.createElement(Skill, {
    name: "Conjure Frost Bow",
    id: "5567"
  }), " for your opening you can do the following:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "Darkrazors Daring",
    id: "41220"
  }), " and ", React.createElement(Skill, {
    name: "icerazors ire",
    id: "40485"
  }), " and change to ", React.createElement(Skill, {
    name: "Legendary Assassin Stance",
    id: "28134"
  }), " ", React.createElement(_components.strong, null, "using the legend swap UI"), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Activate ", React.createElement(Skill, {
    name: "Impossible Odds",
    id: "27107"
  }), ", cast ", React.createElement(Skill, {
    name: "Citadel Bombardment",
    id: "41294"
  }), " and spend time autoattacking."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Change to ", React.createElement(Skill, {
    name: " Legendary Renegade Stance",
    id: "41858"
  }), " and use ", React.createElement(Skill, {
    name: "Surge of the mists",
    id: "28978"
  }), " before continuing with the steps listed above."), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "In slower kills it might be worth sharing ", React.createElement(Boon, {
    name: "Alacrity"
  }), " in the first phase, rather than waiting until the beginning of the second phase."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "In slower kills you will have to swap to staff after the second add and use ", React.createElement(Skill, {
    name: "Surge of the mists",
    id: "28978"
  }), " to break the defiance bar. Casting ", React.createElement(Skill, {
    name: "Darkrazors Daring",
    id: "41220"
  }), " will also be beneficial in this circumstance."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If you are running higher boon duration you will have to use ", React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), " and ", React.createElement(Skill, {
    name: "Shackling Wave",
    id: "28472"
  }), " on your add to kill it quick enough in both phases."), "\n"), "\n"), "\n", React.createElement(Boss, {
    name: "ensolyss",
    video: "eexNfMx2J_w",
    timestamp: "",
    videoCreator: "Deen [dT]",
    foodId: "91805",
    utilityId: "50082",
    legend1Id: "41858",
    legend2Id: "28134",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "sword",
    weapon1MainSigil1: "impact",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "Berserker",
    weapon1OffType: "sword",
    weapon1OffSigil: "serpentslaying",
    weapon1OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "staff",
    weapon2MainSigil1: "severance",
    weapon2MainSigil2: "serpentslaying",
    weapon2MainInfusion1Id: "37131",
    weapon2MainInfusion2Id: "37131",
    video: "eexNfMx2J_w",
    videoCreator: "Deen [dT]",
    foodId: "91805",
    utilityId: "50082",
    legend1Id: "41858",
    legend2Id: "28134",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon2MainInfusion2Id: "37131",
    weapon1MainId: "46774",
    weapon1MainSigil1Id: "24868",
    weapon1OffId: "46774",
    weapon1OffSigilId: "24658",
    weapon2MainId: "46773",
    weapon2MainSigil1Id: "84505",
    weapon2MainSigil2Id: "24658"
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Suggested Boon Duration:"), " ", React.createElement(_components.code, null, "80%")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Maintaining ", React.createElement(Boon, {
    name: "Alacrity"
  }), " in this encounter does require higher boon duration due to the length of the fight, and the necessity for ", React.createElement(Boon, {
    name: "Alacrity"
  }), " throughout the encounter. Increase your concentration if you notice downtime in ", React.createElement(Boon, {
    name: "Alacrity"
  }), "."), "\n")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Ordersfromabove",
    id: "45537"
  }), " for ", React.createElement(Boon, {
    name: "Alacrity"
  }), " and ", React.createElement(Skill, {
    name: "Renewingwave",
    id: "29321"
  }), " to blast ", React.createElement(Boon, {
    name: "Might"
  }), ", take the ", React.createElement(_components.em, null, "Mistlock Singularity"), " and repeat. The two casts of ", React.createElement(Skill, {
    name: "Ordersfromabove",
    id: "45537"
  }), " overlap and grant twice the duration of ", React.createElement(Boon, {
    name: "Alacrity"
  }), ". You can use ", React.createElement(Skill, {
    name: "DroptheHammer",
    id: "28110"
  }), " for an additional ", React.createElement(Boon, {
    name: "Might"
  }), " blast if needed."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "After the introduction of templates, it is recommended to precast using equipment with higher boon duration."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "100-66%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "Darkrazors Daring",
    id: "41220"
  }), ", and ", React.createElement(Skill, {
    name: "Icerazors Ire",
    id: "40485"
  }), " on Ensolyss as soon as the red circle appears."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Surge of the mists",
    id: "28978"
  }), " to break the defiance bar immediately. Be aware of your positioning to gain an additional hit."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Change to ", React.createElement(Skill, {
    name: "Legendary Assassin Stance",
    id: "28134"
  }), " to gain the extra energy from the trait ", React.createElement(Trait, {
    name: "Charged Mists",
    id: "1791"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Weapon swap and share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " with ", React.createElement(Skill, {
    name: "Orders from Above",
    id: "45537"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Activate ", React.createElement(Skill, {
    name: "Impossible Odds",
    id: "27107"
  }), ", and use ", React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), ", ", React.createElement(Skill, {
    name: "Citadel Bombardment",
    id: "41294"
  }), ", ", React.createElement(Skill, {
    name: "Shackling Wave",
    id: "28472"
  }), ", and ", React.createElement(Skill, {
    name: "Deathstrike",
    id: "27074"
  }), "."), "\n"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "In the case of reaching the Upswing attack:")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Change to ", React.createElement(Skill, {
    name: "Legendary Renegade Stance",
    id: "41858"
  }), " and share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " with ", React.createElement(Skill, {
    name: "Orders from Above",
    id: "45537"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Soulcleaves Summit",
    id: "45773"
  }), " and ", React.createElement(Skill, {
    name: "Icerazors Ire",
    id: "40485"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), ", ", React.createElement(Skill, {
    name: "Shackling Wave",
    id: "28472"
  }), ", and ", React.createElement(Skill, {
    name: "Deathstrike",
    id: "27074"
  }), " until phase is finished."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Orb Phases")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Weapon swap to staff and change to ", React.createElement(Skill, {
    name: "Legendary Renegade Stance",
    id: "41858"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Breakrazors Bastion",
    id: "45686"
  }), " on the ", React.createElement(Skill, {
    name: "Frost Spirit",
    id: "12497"
  }), " to keep it alive for the next phase."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "66-33%"), " / ", React.createElement(_components.strong, null, "33-0%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "Darkrazors Daring",
    id: "41220"
  }), " right before Ensolyss appear."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Icerazors Ire",
    id: "40485"
  }), ", ", React.createElement(Skill, {
    name: "Orders from Above",
    id: "45537"
  }), " for ", React.createElement(Boon, {
    name: "Alacrity"
  }), ", ", React.createElement(Skill, {
    name: "Heroic Command",
    id: "44076"
  }), " for ", React.createElement(Boon, {
    name: "Might"
  }), ", and ", React.createElement(Skill, {
    name: "Soulcleaves Summit",
    id: "45773"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Surge of the mists",
    id: "28978"
  }), " to break the defiance bar immediately. Be aware of energy management."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Weapon swap and change to ", React.createElement(Skill, {
    name: "Legendary Assassin Stance",
    id: "28134"
  }), " to gain the extra energy from the trait ", React.createElement(Trait, {
    name: "Charged Mists",
    id: "1791"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Activate ", React.createElement(Skill, {
    name: "Impossible Odds",
    id: "27107"
  }), ", and use ", React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), ", ", React.createElement(Skill, {
    name: "Citadel Bombardment",
    id: "41294"
  }), ", ", React.createElement(Skill, {
    name: "Shackling Wave",
    id: "28472"
  }), ", ", React.createElement(Skill, {
    name: "Deathstrike",
    id: "27074"
  }), ", and ", React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), "."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "After the completion of the Nightmare Devastation attack:")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Weapon swap and change to ", React.createElement(Skill, {
    name: "Legendary Renegade Stance",
    id: "41858"
  }), " and share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " with ", React.createElement(Skill, {
    name: "Orders from Above",
    id: "45537"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Soulcleaves Summit",
    id: "45773"
  }), " and ", React.createElement(Skill, {
    name: "Darkrazors Daring",
    id: "41220"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Surge of the mists",
    id: "28978"
  }), " to break the defiance bar."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "In the case of teleporting to the edge of the platform:")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Change to ", React.createElement(Skill, {
    name: "Legendary Renegade Stance",
    id: "41858"
  }), " and share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " with ", React.createElement(Skill, {
    name: "Orders from Above",
    id: "45537"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Soulcleaves Summit",
    id: "45773"
  }), " and ", React.createElement(Skill, {
    name: "Icerazors Ire",
    id: "40485"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), ", ", React.createElement(Skill, {
    name: "Shackling Wave",
    id: "28472"
  }), ", and ", React.createElement(Skill, {
    name: "Deathstrike",
    id: "27074"
  }), " until phase is finished."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Annotation")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Consider breaking the defiance bar a second time in prolonged phases. Use ", React.createElement(Skill, {
    name: "Surge of the Mists",
    id: "28978"
  }), " and ", React.createElement(Skill, {
    name: "Darkrazors Daring",
    id: "41220"
  }), " after the Upswing attack in the first phase, and after the Nightmare Devastation attack in the second phase. In the third phase use ", React.createElement(Skill, {
    name: "Darkrazors Daring",
    id: "41220"
  }), " on the first defiance bar, and use ", React.createElement(Skill, {
    name: "Surge of the mists",
    id: "28978"
  }), " immediately when the second defiance bar appears."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Using ", React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), " before ", React.createElement(Skill, {
    name: "Citadel Bombardment",
    id: "41294"
  }), " will give you the opportunity to use ", React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), " again while Ensolyss is affected by the ", React.createElement(Effect, {
    name: "Exposed"
  }), " debuff."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Coordinate with the ", React.createElement(Specialization, {
    name: "Soulbeast"
  }), " in which orb phase to use ", React.createElement(Skill, {
    name: "Breakrazors Bastion",
    id: "45686"
  }), " to heal the ", React.createElement(Skill, {
    name: "Frost Spirit",
    id: "12497"
  }), "."), "\n"), "\n"), "\n", React.createElement(Boss, {
    name: "skorvald",
    video: "BVTXG2SMP6Y",
    timestamp: "",
    videoCreator: "Deen [dT]",
    foodId: "91805",
    utilityId: "9443",
    legend1Id: "41858",
    legend2Id: "28419",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "sword",
    weapon1MainSigil1: "impact",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "berserker",
    weapon1OffType: "sword",
    weapon1OffSigil: "force",
    weapon1OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "staff",
    weapon2MainSigil1: "force",
    weapon2MainSigil2: "severance",
    weapon2MainInfusion1Id: "37131",
    weapon2MainInfusion2Id: "37131",
    video: "BVTXG2SMP6Y",
    videoCreator: "Deen [dT]",
    foodId: "91805",
    utilityId: "9443",
    legend1Id: "41858",
    legend2Id: "28419",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon2MainInfusion2Id: "37131",
    weapon1MainId: "46774",
    weapon1MainSigil1Id: "24868",
    weapon1OffId: "46774",
    weapon1OffSigilId: "24615",
    weapon2MainId: "46773",
    weapon2MainSigil1Id: "24615",
    weapon2MainSigil2Id: "84505"
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Suggested Boon Duration:"), " ", React.createElement(_components.code, null, "80%")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Maintaining ", React.createElement(Boon, {
    name: "Alacrity"
  }), " in this encounter does require higher boon duration due to the length of the fight, and the necessity for ", React.createElement(Boon, {
    name: "Alacrity"
  }), " throughout the encounter. Increase your concentration if you notice downtime in ", React.createElement(Boon, {
    name: "Alacrity"
  }), "."), "\n")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Cast ", React.createElement(Skill, {
    name: "Ordersfromabove",
    id: "45537"
  }), " for ", React.createElement(Boon, {
    name: "Alacrity"
  }), " and ", React.createElement(Skill, {
    name: "Renewingwave",
    id: "29321"
  }), " to blast ", React.createElement(Boon, {
    name: "Might"
  }), ", take the ", React.createElement(_components.em, null, "Mistlock Singularity"), " and repeat. The two casts of ", React.createElement(Skill, {
    name: "Ordersfromabove",
    id: "45537"
  }), " overlap and grant twice the duration of ", React.createElement(Boon, {
    name: "Alacrity"
  }), ". You can use ", React.createElement(Skill, {
    name: "DroptheHammer",
    id: "28110"
  }), " for an additional ", React.createElement(Boon, {
    name: "Might"
  }), " blast."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "100-66%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "Darkrazors Daring",
    id: "41220"
  }), " when the ", React.createElement(Specialization, {
    name: "Weaver"
  }), " is casting ", React.createElement(Skill, {
    name: "Meteorshower",
    id: "5501"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Icerazors Ire",
    id: "40485"
  }), " right before triggering the instance."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Surge of the Mists",
    id: "28978"
  }), " through Skorvald to break the defiance bar. Be aware of positioning to gain an extra hit."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Weapon swap and change to ", React.createElement(Skill, {
    name: "Legendary Dwarf Stance",
    id: "28419"
  }), ", this will grant you extra energy from the trait ", React.createElement(Trait, {
    name: "Charged Mists",
    id: "1791"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Citadel Bombardment",
    id: "41294"
  }), ", ", React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), ", and ", React.createElement(Skill, {
    name: "Deathstrike",
    id: "27074"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " with ", React.createElement(Skill, {
    name: "Orders from above",
    id: "45537"
  }), " when the phase ends."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "1st Add")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Might"
  }), " with ", React.createElement(Skill, {
    name: "Heroic Command",
    id: "44076"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Change to ", React.createElement(Skill, {
    name: "Legendary Renegade Stance",
    id: "41858"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Shackling Wave",
    id: "28472"
  }), "."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "2nd Add")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Icerazors Ire",
    id: "40485"
  }), ", and ", React.createElement(Skill, {
    name: "Soulcleaves Summit",
    id: "45773"
  }), " while the add is charging the knockback attack."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Citadel Bombardment",
    id: "41294"
  }), ", ", React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), ", and ", React.createElement(Skill, {
    name: "Heroic Command",
    id: "44076"
  }), " to share ", React.createElement(Boon, {
    name: "Might"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Change to ", React.createElement(Skill, {
    name: "Legendary Dwarf Stance",
    id: "28419"
  }), "."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "3rd Add")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Inspiring Reinforcement",
    id: "28516"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " with ", React.createElement(Skill, {
    name: "Orders from Above",
    id: "45537"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Shackling wave",
    id: "28472"
  }), "."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "4th Add")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Inspiring Reinforcement",
    id: "28516"
  }), ", and activate ", React.createElement(Skill, {
    name: "Vengeful Hammers",
    id: "26557"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Citadel Bombardment",
    id: "41294"
  }), ", share ", React.createElement(Boon, {
    name: "Might"
  }), " with ", React.createElement(Skill, {
    name: "Heroic Command",
    id: "44076"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Change to ", React.createElement(Skill, {
    name: "Legendary Renegade Stance",
    id: "41858"
  }), ", and use ", React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), "."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "66-33%"), " / ", React.createElement(_components.strong, null, "33-0%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " with ", React.createElement(Skill, {
    name: "Orders from Above",
    id: "45537"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Soulcleaves Summit",
    id: "45773"
  }), " and ", React.createElement(Skill, {
    name: "Icerazors Ire",
    id: "40485"
  }), " immediately."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Deplete your energy with ", React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), ", ", React.createElement(Skill, {
    name: "Deathstrike",
    id: "27074"
  }), ", and ", React.createElement(Skill, {
    name: "Shackling Wave",
    id: "28472"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Change to ", React.createElement(Skill, {
    name: "Legendary Dwarf Stance",
    id: "28419"
  }), ", and use ", React.createElement(Skill, {
    name: "Citadel Bombardment",
    id: "41294"
  }), ", ", React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), ", ", React.createElement(Skill, {
    name: "Deathstrike",
    id: "27074"
  }), ", and ", React.createElement(Skill, {
    name: "Shackling Wave",
    id: "28472"
  }), " until the phase is finished."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " with ", React.createElement(Skill, {
    name: "Orders from Above",
    id: "45537"
  }), " when the phase ends."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Adds")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Replicate the skill order mentioned above in the earlier add phase."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Annotation")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Citadel Bombardment",
    id: "41294"
  }), " right as you jump the shockwave of the 2nd and 4th add. This can save you some time with a good party."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Be aware of positioning when sharing ", React.createElement(Boon, {
    name: "Alacrity"
  }), " and ", React.createElement(Boon, {
    name: "Might"
  }), " on the islands. Make sure everyone has landed before sharing."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cancel ", React.createElement(Skill, {
    name: "Surge of the Mists",
    id: "28978"
  }), " once you are through the hitbox to gain time to do more damage in the first phase."), "\n"), "\n"), "\n", React.createElement(Boss, {
    name: "artsariiv",
    video: "Mw1lAIF-n0k",
    timestamp: "",
    videoCreator: "Deen [dT]",
    foodId: "91805",
    utilityId: "9443",
    legend1Id: "41858",
    legend2Id: "28419",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "sword",
    weapon1MainSigil1: "impact",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "berserker",
    weapon1OffType: "sword",
    weapon1OffSigil: "force",
    weapon1OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "staff",
    weapon2MainSigil1: "force",
    weapon2MainSigil2: "severance",
    weapon2MainInfusion1Id: "37131",
    weapon2MainInfusion2Id: "37131",
    video: "Mw1lAIF-n0k",
    videoCreator: "Deen [dT]",
    foodId: "91805",
    utilityId: "9443",
    legend1Id: "41858",
    legend2Id: "28419",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon2MainInfusion2Id: "37131",
    weapon1MainId: "46774",
    weapon1MainSigil1Id: "24868",
    weapon1OffId: "46774",
    weapon1OffSigilId: "24615",
    weapon2MainId: "46773",
    weapon2MainSigil1Id: "24615",
    weapon2MainSigil2Id: "84505"
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Suggested Boon Duration:"), " ", React.createElement(_components.code, null, "80%")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Maintaining ", React.createElement(Boon, {
    name: "Alacrity"
  }), " in this encounter does require higher boon duration due to the length of the fight, and the necessity for ", React.createElement(Boon, {
    name: "Alacrity"
  }), " throughout the encounter. Increase your concentration if you notice downtime in ", React.createElement(Boon, {
    name: "Alacrity"
  }), "."), "\n"), React.createElement(_components.h3, null, React.createElement(_components.strong, null, "For this encounter it is recommended to run the following traits in Devastation trait line:")), React.createElement(Traits, {
    traits1Id: "15",
    traits1: "Devastation",
    traits1SelectedIds: "1767,1786,1754",
    unembossed: true,
    traits1Id: "15",
    traits1SelectedIds: "1767,1786,1754"
  })), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Cast ", React.createElement(Skill, {
    name: "Ordersfromabove",
    id: "45537"
  }), " for ", React.createElement(Boon, {
    name: "Alacrity"
  }), " and ", React.createElement(Skill, {
    name: "Renewingwave",
    id: "29321"
  }), " to blast ", React.createElement(Boon, {
    name: "Might"
  }), ", take the ", React.createElement(_components.em, null, "Mistlock Singularity"), " and repeat. The two casts of ", React.createElement(Skill, {
    name: "Ordersfromabove",
    id: "45537"
  }), " overlap and grant twice the duration of ", React.createElement(Boon, {
    name: "Alacrity"
  }), ". You can use ", React.createElement(Skill, {
    name: "DroptheHammer",
    id: "28110"
  }), " for an additional ", React.createElement(Boon, {
    name: "Might"
  }), " blast."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "100-66%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "Darkrazors Daring",
    id: "41220"
  }), " and ", React.createElement(Skill, {
    name: "Icerazors Ire",
    id: "40485"
  }), " at Artsariiv's location after triggering the encounter."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Manually change to ", React.createElement(Skill, {
    name: "Legendary Dwarf Stance",
    id: "28419"
  }), " using the legend UI before Artsariiv becomes vulnerable."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Shackling Wave",
    id: "28472"
  }), ", and share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " with ", React.createElement(Skill, {
    name: "Orders from Above",
    id: "45537"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Might"
  }), " with ", React.createElement(Skill, {
    name: "Heroic Command",
    id: "44076"
  }), " before using the ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " to the corner."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Weapon swap and use ", React.createElement(Skill, {
    name: "Inspiring Reinforcement",
    id: "28516"
  }), " to grant ", React.createElement(Boon, {
    name: "Stability"
  }), " for the party."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Change to ", React.createElement(Skill, {
    name: "Legendary Renegade Stance",
    id: "41858"
  }), ", and precast ", React.createElement(Skill, {
    name: "Darkrazors Daring",
    id: "41220"
  }), ", ", React.createElement(Skill, {
    name: "Icerazors Ire",
    id: "40485"
  }), ", and ", React.createElement(Skill, {
    name: "Soulcleaves Summit",
    id: "45773"
  }), " at the location where Artsariiv will appear."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Surge of the Mists",
    id: "28978"
  }), " to break the defiance bar. Use it with the ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " to gain additional hits."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Weapon swap and change to ", React.createElement(Skill, {
    name: "Legendary Dwarf Stance",
    id: "28419"
  }), "."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Add")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Forced Engagement",
    id: "26679"
  }), " and ", React.createElement(Skill, {
    name: "Shackling Wave",
    id: "28472"
  }), " to break the adds defiance bar."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "66-33%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " with ", React.createElement(Skill, {
    name: "Orders from Above",
    id: "45537"
  }), " when everyone is in the middle."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Inspiring Reinforcement",
    id: "28516"
  }), " to grant ", React.createElement(Boon, {
    name: "Stability"
  }), " for the large AoE attack."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Change to ", React.createElement(Skill, {
    name: "Legendary Renegade Stance",
    id: "41858"
  }), ", and use ", React.createElement(Skill, {
    name: "Soulcleaves Summit",
    id: "45773"
  }), " and ", React.createElement(Skill, {
    name: "Citadel Bombardment",
    id: "41294"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Might"
  }), " with ", React.createElement(Skill, {
    name: "Heroic Command",
    id: "44076"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), " and ", React.createElement(Skill, {
    name: "Shackling Wave",
    id: "28472"
  }), " to deplete your energy."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Change to ", React.createElement(Skill, {
    name: "Legendary Dwarf Stance",
    id: "28419"
  }), ", and use your ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " to the corner."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Inspiring Reinforcement",
    id: "28516"
  }), " to grant ", React.createElement(Boon, {
    name: "Stability"
  }), " at the corner."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " with ", React.createElement(Skill, {
    name: "Orders from Above",
    id: "45537"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Might"
  }), " with ", React.createElement(Skill, {
    name: "Heroic Command",
    id: "44076"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), " and ", React.createElement(Skill, {
    name: "Shackling Wave",
    id: "28472"
  }), " for additional damage."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Weapon swap to staff when the phase ends."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Add")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Forced Engagement",
    id: "26679"
  }), " on the first add."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Surge of the Mists",
    id: "28978"
  }), " on the second add."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "33-0%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " with ", React.createElement(Skill, {
    name: "Orders from Above",
    id: "45537"
  }), " when everyone is in the middle."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Inspiring Reinforcement",
    id: "28516"
  }), " to grant ", React.createElement(Boon, {
    name: "Stability"
  }), " for the large AoE attack."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Change to ", React.createElement(Skill, {
    name: "Legendary Renegade Stance",
    id: "41858"
  }), ", and use ", React.createElement(Skill, {
    name: "Soulcleaves Summit",
    id: "45773"
  }), " and ", React.createElement(Skill, {
    name: "Citadel Bombardment",
    id: "41294"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Might"
  }), " with ", React.createElement(Skill, {
    name: "Heroic Command",
    id: "44076"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), " and ", React.createElement(Skill, {
    name: "Shackling Wave",
    id: "28472"
  }), " to deplete your energy."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Change to ", React.createElement(Skill, {
    name: "Legendary Dwarf Stance",
    id: "28419"
  }), ", and use your ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " to the corner."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Inspiring Reinforcement",
    id: "28516"
  }), " to grant ", React.createElement(Boon, {
    name: "Stability"
  }), " at the corner."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " with ", React.createElement(Skill, {
    name: "Orders from Above",
    id: "45537"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Might"
  }), " with ", React.createElement(Skill, {
    name: "Heroic Command",
    id: "44076"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), " and ", React.createElement(Skill, {
    name: "Shackling Wave",
    id: "28472"
  }), " for additional damage."), "\n"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Annotation")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "After using ", React.createElement(Skill, {
    name: "Inspiring Reinforcement",
    id: "28516"
  }), ", make sure you gain the extra energy from ", React.createElement(Trait, {
    name: "Charged Mists",
    id: "1791"
  }), " in the first phase."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Time ", React.createElement(Skill, {
    name: "Inspiring Reinforcement",
    id: "28516"
  }), " so that ", React.createElement(Boon, {
    name: "Stability"
  }), " lasts long enough for the AoE attack in the middle."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " while using ", React.createElement(Skill, {
    name: "Surge of the Mists",
    id: "28978"
  }), " to break the defiance bar as quickly as possible."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Be aware of energy management before changing to ", React.createElement(Skill, {
    name: "Legendary Renegade Stance",
    id: "41858"
  }), " in the middle in order to gain the extra energy from ", React.createElement(Trait, {
    name: "Charged Mists",
    id: "1791"
  }), "."), "\n"), "\n"), "\n", React.createElement(Boss, {
    name: "arkk",
    video: "yadsI_KvvDo",
    timestamp: "",
    videoCreator: "Deen [dT]",
    foodId: "43360",
    utilityId: "50082",
    legend1Id: "41858",
    legend2Id: "28134",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "sword",
    weapon1MainSigil1: "impact",
    weapon1MainInfusion1Id: "37131",
    weapon1OffAffix: "berserker",
    weapon1OffType: "sword",
    weapon1OffSigil: "force",
    weapon1OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "staff",
    weapon2MainSigil1: "force",
    weapon2MainSigil2: "severance",
    weapon2MainInfusion1Id: "37131",
    weapon2MainInfusion2Id: "37131",
    video: "yadsI_KvvDo",
    videoCreator: "Deen [dT]",
    foodId: "43360",
    utilityId: "50082",
    legend1Id: "41858",
    legend2Id: "28134",
    weapon1MainInfusion1Id: "37131",
    weapon1OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon2MainInfusion2Id: "37131",
    weapon1MainId: "46774",
    weapon1MainSigil1Id: "24868",
    weapon1OffId: "46774",
    weapon1OffSigilId: "24615",
    weapon2MainId: "46773",
    weapon2MainSigil1Id: "24615",
    weapon2MainSigil2Id: "84505"
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Suggested Boon Duration:"), " ", React.createElement(_components.code, null, "80%")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Maintaining ", React.createElement(Boon, {
    name: "Alacrity"
  }), " in this encounter does require higher boon duration due to the length of the fight, and the necessity for ", React.createElement(Boon, {
    name: "Alacrity"
  }), " throughout the encounter. Increase your concentration if you notice downtime in ", React.createElement(Boon, {
    name: "Alacrity"
  }), "."), "\n"), React.createElement(_components.h3, null, React.createElement(_components.strong, null, "For this encounter it is recommended to run the following traits in Devastation trait line:")), React.createElement(Traits, {
    traits1Id: "15",
    traits1: "Devastation",
    traits1SelectedIds: "1767,1786,1754",
    unembossed: true,
    traits1Id: "15",
    traits1SelectedIds: "1767,1786,1754"
  })), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Cast ", React.createElement(Skill, {
    name: "Ordersfromabove",
    id: "45537"
  }), " for ", React.createElement(Boon, {
    name: "Alacrity"
  }), " and ", React.createElement(Skill, {
    name: "Renewingwave",
    id: "29321"
  }), " to blast ", React.createElement(Boon, {
    name: "Might"
  }), ", take the ", React.createElement(_components.em, null, "Mistlock Singularity"), " and repeat. The two casts of ", React.createElement(Skill, {
    name: "Ordersfromabove",
    id: "45537"
  }), " overlap and grant twice the duration of ", React.createElement(Boon, {
    name: "Alacrity"
  }), ". You can use ", React.createElement(Skill, {
    name: "DroptheHammer",
    id: "28110"
  }), " for an additional ", React.createElement(Boon, {
    name: "Might"
  }), " blast."), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "100-80%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Soulcleaves Summit",
    id: "45773"
  }), " on Arkk and auto-attack until the Anomaly appear."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Icerazors Ire",
    id: "40485"
  }), " on the Anomaly."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Change to ", React.createElement(Skill, {
    name: "Legendary Assassin Stance",
    id: "28134"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Activate ", React.createElement(Skill, {
    name: "Impossible Odds",
    id: "27107"
  }), ", and use ", React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), " and ", React.createElement(Skill, {
    name: "Shackling Wave",
    id: "28472"
  }), " to finish the Anomaly."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Deathstrike",
    id: "27074"
  }), " to quickly get back to Arkk."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " with ", React.createElement(Skill, {
    name: "Orders from Above",
    id: "45537"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Deplete your energy with ", React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Weapon swap and change to ", React.createElement(Skill, {
    name: "Legendary Renegade Stance",
    id: "41858"
  }), " when the phase is finished."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "80-70%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "Darkrazors Daring",
    id: "41220"
  }), ", ", React.createElement(Skill, {
    name: "Icerazors Ire",
    id: "40485"
  }), ", and ", React.createElement(Skill, {
    name: "Soulcleaves Summit",
    id: "45773"
  }), " at Arkk's location."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " with ", React.createElement(Skill, {
    name: "Orders from Above",
    id: "45537"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Surge of the Mists",
    id: "28978"
  }), " with the ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " to break the defiance bar."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Might"
  }), " with ", React.createElement(Skill, {
    name: "Heroic Command",
    id: "44076"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Weapon swap and change to ", React.createElement(Skill, {
    name: "Legendary Assassin Stance",
    id: "28134"
  }), "."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Archdiviner")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Activate ", React.createElement(Skill, {
    name: "Impossible Odds",
    id: "27107"
  }), ", and use ", React.createElement(Skill, {
    name: "Citadel Bombardment",
    id: "41294"
  }), ", ", React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), ", and ", React.createElement(Skill, {
    name: "Shackling Wave",
    id: "28472"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " if the defiance bar appears."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Change to ", React.createElement(Skill, {
    name: "Legendary Renegade Stance",
    id: "41858"
  }), " when the phase is finished."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "70-50%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Soulcleaves Summit",
    id: "45773"
  }), ", and ", React.createElement(Skill, {
    name: "Icerazors Ire",
    id: "40485"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " with ", React.createElement(Skill, {
    name: "Orders from Above",
    id: "45537"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Might"
  }), " with ", React.createElement(Skill, {
    name: "Heroic Command",
    id: "44076"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Change to ", React.createElement(Skill, {
    name: "Legendary Assassin Stance",
    id: "28134"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Activate ", React.createElement(Skill, {
    name: "Impossible Odds",
    id: "27107"
  }), ", and use ", React.createElement(Skill, {
    name: "Citadel Bombardment",
    id: "41294"
  }), ", ", React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), ", and ", React.createElement(Skill, {
    name: "Shackling Wave",
    id: "28472"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Weapon swap and change to ", React.createElement(Skill, {
    name: "Legendary Renegade Stance",
    id: "41858"
  }), " when the phase is finished."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "50-40%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "Darkrazors Daring",
    id: "41220"
  }), ", ", React.createElement(Skill, {
    name: "Icerazors Ire",
    id: "40485"
  }), ", and ", React.createElement(Skill, {
    name: "Soulcleaves Summit",
    id: "45773"
  }), " at Arkk's location."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " with ", React.createElement(Skill, {
    name: "Orders from Above",
    id: "45537"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Surge of the Mists",
    id: "28978"
  }), " with the ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " to break the defiance bar."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Might"
  }), " with ", React.createElement(Skill, {
    name: "Heroic Command",
    id: "44076"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Weapon swap and change to ", React.createElement(Skill, {
    name: "Legendary Assassin Stance",
    id: "28134"
  }), "."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Gladiator")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Activate ", React.createElement(Skill, {
    name: "Impossible Odds",
    id: "27107"
  }), ", and use ", React.createElement(Skill, {
    name: "Citadel Bombardment",
    id: "41294"
  }), ", ", React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), ", and ", React.createElement(Skill, {
    name: "Shackling Wave",
    id: "28472"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " if the defiance bar appear."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Change to ", React.createElement(Skill, {
    name: "Legendary Renegade Stance",
    id: "41858"
  }), " when the phase is finished."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "40-30%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " with ", React.createElement(Skill, {
    name: "Orders from Above",
    id: "45537"
  }), " in the middle."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Icerazors Ire",
    id: "40485"
  }), " on the Anomaly."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), " and ", React.createElement(Skill, {
    name: "Shackling wave",
    id: "28472"
  }), " to finish of the Anomaly."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Weapon swap at the end of the phase."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "30-0%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "Darkrazors Daring",
    id: "41220"
  }), ", ", React.createElement(Skill, {
    name: "Icerazors Ire",
    id: "40485"
  }), ", and ", React.createElement(Skill, {
    name: "Soulcleaves Summit",
    id: "45773"
  }), " at Arkk's location."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " with ", React.createElement(Skill, {
    name: "Orders from Above",
    id: "45537"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Surge of the Mists",
    id: "28978"
  }), " with the ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " to break the defiance bar."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Weapon swap and change to ", React.createElement(Skill, {
    name: "Legendary Assassin Stance",
    id: "28134"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Alacrity"
  }), " with ", React.createElement(Skill, {
    name: "Orders from Above",
    id: "45537"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Activate ", React.createElement(Skill, {
    name: "Impossible Odds",
    id: "27107"
  }), ", and use ", React.createElement(Skill, {
    name: "Citadel Bombardment",
    id: "41294"
  }), ", ", React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), ", and ", React.createElement(Skill, {
    name: "Shackling Wave",
    id: "28472"
  }), " until the encounter is finished."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Annotation")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Kill the Anomaly in the 70-50% phase if your party struggles to survive without the ", React.createElement(_components.em, null, "Mistlock Singularity"), " for the remainder of the encounter."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Icerazors Ire",
    id: "40485"
  }), ", ", React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), ", and ", React.createElement(Skill, {
    name: "Shackling Wave",
    id: "28472"
  }), " to finish the Anomaly in this case."), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Weapon swap back to swords as early as you can in the phases where you break the defiance bar."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Precast ", React.createElement(Skill, {
    name: "Darkrazors Daring",
    id: "41220"
  }), " early enough to gain energy for ", React.createElement(Skill, {
    name: "Surge of the Mists",
    id: "28978"
  }), " in the opening of 80-70%, 50-40%, and 30-0%."), "\n"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
